/**
 * HERO CAROUSEL
 * @description : Swiper for Hero Carousel modules
 */

(function () {

    var mediaCarouselEls = document.querySelectorAll('.js-hero-carousel');

    mediaCarouselEls.forEach(function (mediaCarouselEl) {

        var swiperEl = mediaCarouselEl.querySelector('.js-hero-carousel__swiper');
        var slideEls = mediaCarouselEl.querySelectorAll('.js-hero-carousel__slide');

        if (swiperEl && slideEls.length > 1) {
            var paginationEl = mediaCarouselEl.querySelector('.js-hero-carousel__pagination');

            var carouselSwiper = new Swiper(swiperEl, {
                spaceBetween: 0,
                slidesPerView: 1,
                direction: 'horizontal',
                loop: true,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: true
                },
                slidesPerView: 1,
                direction: 'horizontal',
                speed: 500,
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    clickable: true,
                    bulletClass: 'c-carousel-pagination__dot',
                    bulletActiveClass: 'c-carousel-pagination__dot--active'
                },
                breakpoints: {
                    [window.breakpoints['sm']]: {
                        speed: 750
                    },
                    [window.breakpoints['md']]: {
                        speed: 1000
                    },
                    [window.breakpoints['lg']]: {
                        speed: 1250
                    },
                    [window.breakpoints['xl']]: {
                        speed: 1625
                    }
                }
            });

            var bandSwiperEl = mediaCarouselEl.querySelector('.js-hero-carousel__band-swiper');
            if (!bandSwiperEl) {
                return;
            }

            var bandSwiper = new Swiper(bandSwiperEl, {
                spaceBetween: 0,
                slidesPerView: 1,
                direction: 'horizontal',
                loop: true,
                slidesPerView: 1,
                direction: 'horizontal',
                speed: 1500
            });

            carouselSwiper.controller.control = bandSwiper;
            bandSwiper.controller.control = carouselSwiper;
        }

    });
})();